import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useQuery} from "react-query";
import {Helmet} from "react-helmet";
import LoadingSpinner from "./LoadingSpinner";
import React from "react";
import DOMPurify from "dompurify";
import SearchPagination from "./SeachPagination";
import LongArticleCard from "./LongArticleCard";
import Sidebar from "./Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import {Button, InputGroup} from "react-bootstrap";
import TagsBar from "./TagsBar";

export default function Search() {


    const navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        navigate({
            pathname: "/search",
            search: createSearchParams({
                "q": event.target.q.value
            }).toString()
        });
    }

    let [searchParams, setSearchParams] = useSearchParams()
    let q = searchParams.get("q")
    let page = searchParams.get("page")
    if ( page === null ) { page = 1 }
    let emptyQuery = false
    if( q === null || q.trim() === '') {
        emptyQuery = true
    }

    function getArticles() {
        if( !emptyQuery ) {
            return axios.get("https://www.litemoods.com/nodeapp/node/articles/query/" + q + "/" + page).then(res => res.data);
        }
    }

    const { data, isLoading, isError, isFetching } = useQuery("search_" + q + "_" + page, getArticles);
    return (
        <>
            { data && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ DOMPurify.sanitize(q, {}) }</title>
                </Helmet>
            )}
            <div className="col-12 clearfix">
                { isLoading && <LoadingSpinner /> }
                <div className="">
                    <div className="col-12 text-center">
                        <div className="col-12 bg-dark text-white p-3">
                            <h1 className="fw-semi-bold mb-0">
                                بحث
                            </h1>
                        </div>
                    </div>
                    <div className="container mt-1 h-100 clearfix" style={{ minHeight: window.innerHeight + "px" }}>
                        <div className="col-12 col-lg-8 float-start">
                            <div className="card rounded-0 border-0 border-bottom">
                                <div className="card-body text-start fs-5">
                                    لقد قمت بالبحث عن "{ DOMPurify.sanitize(q, {}) }"
                                </div>
                            </div>
                            { emptyQuery && (
                                <div className="col-12 text-center w-100 pt-5">
                                    <h4 className="text-black-50 fw-bold">الرجاء إدخال كلمات بحثية للبحث</h4>
                                </div>
                            ) }
                            { data && !isLoading && data.map( (item, i) => {
                                return (
                                    <LongArticleCard data={item} key={item.article_id}/>
                                )
                            }) }
                            { data && data.length === 0 && (
                                <div className="col-12 text-center w-100 pt-5">
                                    <h4 className="text-black-50 fw-bold">لم يتم العثور على أي نتائج لبحثك</h4>
                                </div>
                            )}
                        </div>
                        <div className="d-none d-lg-block col-lg-4 float-start">
                            <div className="p-3 bg-white border rounded-3 mb-3">
                                <h2 className="pb-2"><span>بحث</span> <FontAwesomeIcon className="fs-4 text-secondary" icon={ faSearch } /></h2>
                                <Form className="d-flex" onSubmit={ handleSubmit }>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="search"
                                            placeholder="بحث"
                                            className=""
                                            aria-label="Search"
                                            name="q"
                                            defaultValue={q}
                                        />
                                        <Button variant="primary" type="submit">
                                            بحث
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </div>
                            <TagsBar count="20" />
                            <Sidebar count="10" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 py-2">
                { !emptyQuery && <SearchPagination q={q} page={page} /> }
            </div>
        </>
    )
}