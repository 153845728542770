import React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import TagPaginationButtons from "./TagPaginationButtons";

export default function TagPagination(props) {
    function getData() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/pagination/tag/" + props.tag).then(res => res.data);
    }
    const { data, isLoading, isError, isFetching } = useQuery("tagPagination_" + props.tag, getData);

    return (
        <>
            <div className="text-center p-3 bg-light border border-2 my-3 border-start-0 border-end-0">
                <div className="col-12">
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-md justify-content-center mb-0">
                            { isLoading && <LocalLoadingSpinner /> }
                            { data && !isLoading && <TagPaginationButtons page={ props.page } data={ data } tag={props.tag} />}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}
