import React from "react";
import {Link} from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import DOMPurify from "dompurify";

export default function ArticleCardSimple(props)
{
    // Create an HTML sanitizer to filter XSS
    const sanitizer = DOMPurify.sanitize;
    let borderClass = 'col-12 float-start text-start p-0 border-bottom';
    if( props.maxCount - 1 === props.itemIndex ) {
        borderClass = 'col-12 float-start text-start p-0';
    }
    return (
        <div className={borderClass}>
            <div className="card p-0 border-0 m-0">
                <div className="card-body">
                    <h6 className="card-title">
                        <Link className="text-dark text-decoration-none" to={"/article/" + props.data.article_id + "/" + props.data.title.replaceAll(' ', '-')}>
                            <Dotdotdot clamp={2}>
                                {sanitizer(props.data.title)}
                            </Dotdotdot>
                        </Link>
                    </h6>
                </div>
            </div>
        </div>
    )
}