import React from "react"
import axios from "axios";
import {useQuery} from "react-query";
import ArticleCard from "./ArticleCard";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";
import LongArticleCard from "./LongArticleCard";

export default function HomepageSection(props) {
    const page = 1
    function getArticles() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/cat/" + props.data.id + "/count/4").then(res => res.data);
    }
    const { data, isLoading, isError, isFetching } = useQuery("category_" + props.data.id + "_" + page, getArticles);
    const sanitizer = DOMPurify.sanitize;
    return (
        <>
        { data && data.length > 0 && (
            <div className="col-12 clearfix mb-3 border-bottom border-4 pb-3">
                <h2><Link className="text-primary fw-bolder text-decoration-none border-info border-4 border-start ps-2" to={"/cat/" + props.data.id + "/" + props.data.title}>{sanitizer(props.data.title)}</Link></h2>
                <div className="col-12 clearfix">
                    { isLoading && <LocalLoadingSpinner /> }
                    <div className="">
                        <div className="container mt-1 px-0 clearfix">
                            { data && !isLoading && data.map( (item, i) => {
                                return (
                                    <LongArticleCard coverphoto="med_photos" data={item} key={item.article_id}/>
                                )
                            }) }
                            <div className="col-12 p-0 d-flex justify-content-center pt-2">
                                <Link className="btn btn-light w-25 border-dark shadow-sm rounded-0 text-decoration-none-hover" to={"/cat/" + props.data.id + "/" + props.data.title}>رؤية المزيد</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}