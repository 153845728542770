import React from "react";

export default function LoadingSpinner() {
    return (
        <div className="position-fixed p-1 bg-light shadow rounded rounded-circle border border-2" style={{ top: "50px", left: "49%", zIndex: "2"}}>
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}
