import React from "react";
import DOMPurify from 'dompurify';
import {Link} from "react-router-dom";
import { DiscussionEmbed } from 'disqus-react';
import {Image} from "./Image";
import "../assets/css/Main.css"
import Tags from "./Tags";
import {Helmet} from "react-helmet";
import { faEye, faShare } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ArticleBody extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Create an HTML sanitizer to filter XSS
        const sanitizer = DOMPurify.sanitize;
        const dateTime = new Date(this.props.data.date);
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ this.props.data.title }</title>
                </Helmet>

                <div className="bg-white border rounded-0 overflow-hidden mb-5">
                    <div className="article_image pb-4">
                        <Image data={{'coverphoto_type':  'med_photos', 'coverphoto': sanitizer(this.props.data.coverphoto)}}/>
                    </div>
                    <div className="col-12 px-4 clearfix">
                        <div className="text-black-50 fs-6 float-start me-2 border rounded-1 p-1">{ sanitizer(dateTime.toLocaleString()) }</div>
                        <div className="text-black-50 fs-6 float-start border rounded-1 p-1">{ sanitizer(this.props.data.view_count) } <FontAwesomeIcon className="ps-1" icon={ faEye } /> </div>
                    </div>
                    <div className="article_title p-4">

                        <div className="article_cat_holder">
                            <h5><Link className="text-primary fw-bolder text-decoration-none border-info border-4 border-start ps-2" to={"/cat/" + this.props.data.cat_id + "/" + this.props.data.cat_title}>{sanitizer(this.props.data.cat_title)}</Link></h5>
                        </div>
                        <h1 className="fw-bolder">
                            {sanitizer(this.props.data.title)}
                        </h1>

                        <div className="col-12">
                            <Tags articleId={this.props.data.article_id} />
                        </div>

                        <div className="socialShareButtonsWrapper p-2 clearfix border mt-5 d-flex align-items-center">
                            <span className="float-start pe-2 text-muted">شارك <FontAwesomeIcon icon={ faShare } /></span>
                            <a target="_blank" className="text-primary fs-5 float-start mx-1" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href }><FontAwesomeIcon icon={faFacebook} /></a>
                            <a target="_blank" className="text-info fs-5 float-start mx-1" href={"https://twitter.com/share?text=" + window.location.href }><FontAwesomeIcon icon={faTwitter} /></a>
                            <a target="_blank" className="text-success fs-5 float-start mx-1" href={"whatsapp://send?text=" + window.location.href }><FontAwesomeIcon icon={faWhatsapp} /></a>
                        </div>

                    </div>

                    <div className="article_body p-4 pt-0">
                        <div style={{ fontSize: '20px' }} dangerouslySetInnerHTML={{__html: sanitizer(this.props.data.description)}} />
                    </div>

                    <div className="col-12 p-4">
                        <DiscussionEmbed
                            shortname='fadiobaji'
                            config={
                                {
                                    url: "https://www.litemoods.com/nodeapp/node/articles/" + this.props.data.article_id,
                                    identifier: this.props.data.article_id,
                                    title: this.props.data.title,
                                    language: 'ar_AR'
                                }
                            }
                        />
                    </div>
                    <div className="clearfix"></div>
                </div>
            </>
        )
    }
}