import {useParams} from "react-router-dom";
import axios from "axios";
import {useQuery} from "react-query";
import {Helmet} from "react-helmet";
import LoadingSpinner from "./LoadingSpinner";
import ArticleCard from "./ArticleCard";
import Pagination from "./Pagination";
import React from "react";
import TagPagination from "./TagPagination";
import LongArticleCard from "./LongArticleCard";

export default function Tag() {
    let { tag, page } = useParams()
    if ( page === undefined ) { page = 1 }
    function getArticles() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/tag/" + tag + "/" + page).then(res => res.data);
    }

    const { data, isLoading, isError, isFetching } = useQuery("tag_" + tag + "_" + page, getArticles);
    return (
        <>
            { data && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>#{ tag }</title>
                </Helmet>
            )}
            <div className="col-12 clearfix">
                { isLoading && <LoadingSpinner /> }
                <div className="text-center">
                    <div className="col-12">
                        <div className="col-12 bg-dark text-white p-3">
                            { data && (
                                <h1 className="fw-semi-bold mb-0">
                                    #{ tag }
                                </h1>
                            ) }
                        </div>
                    </div>
                    <div className="container clearfix mt-1 h-100" style={{ minHeight: window.innerHeight + "px" }}>
                        <div className="col-12 col-lg-8 float-start">
                        { data && !isLoading && data.map( (item, i) => {
                            return (
                                <LongArticleCard data={item} key={item.article_id}/>
                            )
                        }) }
                        { data && data.length === 0 && (
                            <div className="col-12 text-center w-100 pt-5">
                                <h4 className="text-black-50 fw-bold">لا يوجد محتوى حالياً</h4>
                            </div>
                        )}
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>

            <div className="col-12 py-2">
                <TagPagination page={ page } tag={ tag } />
            </div>
        </>
    )
}