import React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import SearchPaginationButtons from "./SearchPaginationButtons";

export default function SearchPagination(props) {
    let q = props.q
    let emptyQuery = false
    if( q === undefined || q.trim() === '') {
        emptyQuery = true
    }

    function getData() {
        if( !emptyQuery ) {
            return axios.get("https://www.litemoods.com/nodeapp/node/articles/pagination/query/" + q).then(res => res.data);
        }
    }
    const { data, isLoading, isError, isFetching } = useQuery("searchPagination_" + q, getData);

    return (
        <>
            <div className="text-center p-3 bg-light border border-2 my-3 border-start-0 border-end-0">
                <div className="col-12">
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-md justify-content-center mb-0">
                            { emptyQuery && ( <></> ) }
                            { isLoading && <LocalLoadingSpinner /> }
                            { data && !isLoading && <SearchPaginationButtons page={ props.page } data={ data } q={q} />}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}