import React from "react"

export default function FixedPageHeader(props){
    return(
        <div className="col-12 bg-dark text-white p-3">
                <h1 className="fw-semi-bold mb-0">
                    { props.htmlTitle }
                </h1>
        </div>
    )
}