import { useEffect } from "react";
import {useLocation, useSearchParams} from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams()
    let q = searchParams.get("q")
    let page = searchParams.get("page")
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, q, page]);

    return null;
}