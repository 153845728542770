import React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import ArticleCardSimple from "./ArticleCardSimple";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";

function Sidebar (props){

    function fetchArticle() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/most/" + props.count).then(res => res.data);
    }
    const { data, isLoading, isFetching, isError } = useQuery("articlesMostRead10", fetchArticle);

    return (
        <div id="sidebar" className="p-3 bg-white border rounded-3 overflow-hidden mb-3">
            <h2 className="pb-2"><span>الأكثر قراءة</span> <FontAwesomeIcon className="fs-4 text-secondary" icon={ faArrowTrendUp } /></h2>
            <div>
                { isLoading && <LocalLoadingSpinner /> }
                { data && !isLoading && data.map( (item, i) => {
                    return (
                        <ArticleCardSimple maxCount={ props.count } itemIndex={i} data={item} key={item.article_id}/>
                    )
                }) }
            </div>
        </div>
    )
}

export default Sidebar;