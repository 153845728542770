import React from "react";
import { Link } from "react-router-dom";

export default function SearchPaginationButtons(props) {
    const paginationLinksCount = Math.ceil(parseInt(props.data.article_count) / parseInt(props.data.limit))
    let paginationLinksArray = []
    let paginationLinksToShow = 3
    let page = parseInt( props.page )
    switch ( page ) {
        case 1: // If first page
            let i = 1
            for( i; i <= paginationLinksToShow; i++ ) {
                if( i > paginationLinksCount ) {
                    continue
                }

                paginationLinksArray[i] = {
                    'route': "/search?q=" + props.q + "&page=" + i,
                    'html': i
                }
            }

            paginationLinksArray[0] = {
                'route': "/search?q=" + props.q + "&page=" + 1,
                'html': 'الأولى',
                'disabled': true
            }

            let lastDisabled = true
            if( paginationLinksCount > 1) {
                lastDisabled = false
            }
            paginationLinksArray[paginationLinksCount+1] = {
                'route': "/search?q=" + props.q + "&page=" + paginationLinksCount,
                'html': 'الأخيرة',
                'disabled': lastDisabled
            }
            break
        case paginationLinksCount: // If last page
            let j = paginationLinksCount
            for( j ; j > paginationLinksCount - 3; j-- ) {
                if( j < 1 ) { continue }
                paginationLinksArray[j] = {
                    'route': "/search?q=" + props.q + "&page=" + j,
                    'html': j
                }
            }

            paginationLinksArray[0] = {
                'route': "/search?q=" + props.q + "&page=" + 1,
                'html': 'الأولى'
            }

            paginationLinksArray[paginationLinksCount+1] = {
                'route': "/search?q=" + props.q + "&page=" + paginationLinksCount,
                'html': 'الأخيرة',
                'disabled': true
            }
            break
        default: // If in between
            let after = page + 1
            let before = page - 1
            paginationLinksArray[page] = {
                'route': "/search?q=" + props.q + "&page=" + page,
                'html': page
            }
            paginationLinksArray[after] = {
                'route': "/search?q=" + props.q + "&page=" + after,
                'html': after
            }
            paginationLinksArray[before] = {
                'route': "/search?q=" + props.q + "&page=" + before,
                'html': before
            }

            paginationLinksArray[0] = {
                'route': "/search?q=" + props.q + "&page=" + 1,
                'html': 'الأولى'
            }

            paginationLinksArray[paginationLinksCount+1] = {
                'route': "/search?q=" + props.q + "&page=" + paginationLinksCount,
                'html': 'الأخيرة'
            }
            break
    }

    if(paginationLinksCount === 0) {
        paginationLinksArray = []
    }

    return (
        <>
            { paginationLinksArray.map((item, i) => {
                if( i === page ) {
                    return (
                        <li className="page-item active" key={i.toString()}>
                            <span className="page-link">
                                {item.html}
                            </span>
                        </li>
                    )
                } else {
                    if( item.disabled ) {
                        return (
                            <li className="page-item disabled" key={i.toString()}>
                                <span className="page-link">
                                    {item.html}
                                </span>
                            </li>
                        )
                    } else {
                        return (
                            <li className="page-item" key={i.toString()}>
                                <Link className="page-link" to={item.route}>
                                    {item.html}
                                </Link>
                            </li>
                        )
                    }
                }
            })}
        </>
    )
}