import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {useQuery} from "react-query";
import lazemniLogo from "../assets/img/lazemni_logo.jpg"
import { faPlug } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Footer(props) {
    function fetchHeader() {
        return axios.get("https://www.litemoods.com/nodeapp/node/categories/main").then(res => res.data);
    }

    const { data, isLoading, isFetching, isError } = useQuery("header", fetchHeader);
    return (
        <>
        <div className="w-100 bg-light">
            <div className="container p-5 border-3 border-top border-dark">
                <div className="row">
                    <div className="col-lg-4">
                        <h5 className="text-uppercase text-dark opacity-85 mb-3">لايت مودز</h5>
                        <p className="text-dark col-8">
                            لايت مودز شبكة إعلامية مستقلة غير مرتبطة بأجندات سياسية أو حقوقية أو أخرى.
                        </p>
                    </div>
                    <div className="col ps-lg-6 ps-xl-8">
                        <div className="row mt-5 mt-lg-0">
                            <div className="col-6 col-md-3">
                                <h5 className="text-uppercase text-dark opacity-85 mb-3">روابط</h5>
                                <ul className="list-unstyled">
                                    <li className="mb-1">
                                        <Link className="text-dark text-decoration-none"
                                                            to="/">رئيسية</Link>
                                    </li>
                                    <li className="mb-1">
                                        <Link className="text-dark text-decoration-none"
                                                            to="/about">عن الموقع</Link>
                                    </li>
                                    <li className="mb-1">
                                        <Link className="text-dark text-decoration-none"
                                                            to="/contact">اتصل بنا</Link></li>

                                </ul>
                            </div>
                            <div className="col-6 col-md-3">
                                <h5 className="text-uppercase text-dark opacity-85 mb-3">سوشال ميديا</h5>
                                <ul className="list-unstyled">
                                    <li className="mb-1">
                                        <a target="_blank" className="text-dark text-decoration-none" href="https://www.facebook.com/LiteMoods">فيسبوك</a>
                                    </li>
                                    <li className="mb-1">
                                        <a target="_blank" className="text-dark text-decoration-none" href="https://www.linkedin.com/company/lite-moods/">لينكد إن</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col mt-5 mt-md-0">
                                <h5 className="text-uppercase text-dark opacity-85 mb-3">أقسام الموقع</h5>
                                <ul className="list-unstyled">
                                    { isLoading && (
                                        <li className="d-flex align-items-center">
                                            <div className="spinner-grow spinner-grow-sm" role="status"></div>
                                            <div className="spinner-grow spinner-grow-sm" role="status"></div>
                                            <div className="spinner-grow spinner-grow-sm" role="status"></div>
                                        </li>

                                    ) }
                                    { data && data.map((item, i) => (
                                        <li key={i} className="mb-1">
                                            <Link className="text-dark text-decoration-none" aria-current="page" to={ "/cat/" + item.id + "/" + item.title + "/1" }>{ item.title }</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="w-100 bg-light border-top border-2 text-center py-3">
                <div className="container clearfix">
                    <div className="col-12 col-md-6 float-start">

                            <div className="clearfix d-flex align-items-center">
                                <a className="" target="_blank" href="https://www.lazemni.com/">
                                    <img src={lazemniLogo} alt="Lazemni" className="img-fluid float-start" width="100" />
                                </a>
                                <div className="float-start ps-2 text-muted">Powered by <FontAwesomeIcon icon={faPlug} /></div>

                            </div>

                    </div>
                    <div className="col-12 col-md-6 float-start">
                        <div className="text-muted text-start text-md-end">
                            جميع الحقوق محفوظة © {new Date().getFullYear()} شبكة لايت مودز
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}