import React from "react";
import {Link} from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import DOMPurify from "dompurify";
import {Image} from "./Image";

export default function HomeFeatureArticleCard(props)
{
    // Create an HTML sanitizer to filter XSS
    const sanitizer = DOMPurify.sanitize;
    let coverphoto
    switch (props.coverphotoType) {
        case "med_photos":
            coverphoto = "med_photos"
            break;
        case "thumbs":
            coverphoto = "thumbs"
            break;
        case "photos":
            coverphoto = "photos"
            break;
    }
    return (
        <div className="col-12 float-start text-start p-1 pt-0">
            <div className="card border-0 shadow-none">
                <Image data={{'coverphoto_type': coverphoto, 'coverphoto': sanitizer(props.data.coverphoto)}}/>
                <div className="card-body px-0">
                    { props.data.cat_title && (
                        <Link className="text-primary fw-bolder text-decoration-none border-info border-4 border-start ps-2" to={"/cat/" + props.data.cat_id + "/" + props.data.cat_title}>{props.data.cat_title}</Link>
                    ) }
                    { props.titleType === 'h6' && (
                        <h6 className="card-title py-2">
                            <Link className="text-dark text-decoration-none" to={"/article/" + props.data.article_id + "/" + props.data.title.replaceAll(' ', '-')}>
                                <Dotdotdot clamp={2}>
                                    {DOMPurify.sanitize(props.data.title)}
                                </Dotdotdot>
                            </Link>
                        </h6>
                    ) }

                    { props.titleType === 'h4' && (
                        <h4 className="card-title py-2">
                            <Link className="text-dark text-decoration-none" to={"/article/" + props.data.article_id + "/" + props.data.title.replaceAll(' ', '-')}>
                                    {DOMPurify.sanitize(props.data.title)}
                            </Link>
                        </h4>
                    )}

                    { props.titleType === 'h2' && (
                        <h2 className="card-title py-2">
                            <Link className="text-dark text-decoration-none" to={"/article/" + props.data.article_id + "/" + props.data.title.replaceAll(' ', '-')}>
                                    {DOMPurify.sanitize(props.data.title)}
                            </Link>
                        </h2>
                    )}

                    { props.teaser !== "false" && (
                            <p className="card-text text-black-50 fs-6 p-2">{DOMPurify.sanitize(props.data.teaser)}</p>
                    ) }
                </div>
            </div>
        </div>
    )
}