import React from "react";
import ArticleCard from "./ArticleCard";
import Sidebar from "./Sidebar";
import axios from "axios";
import {useQuery} from "react-query";
import HomeFeatureArticleCard from "./HomeFeatureArticleCard";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import LongArticleCard from "./LongArticleCard";

export default function HomePageFeature () {

    function getFeatureArticles() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/count/4").then(res => res.data);
    }

    const { data, isLoading, isFetching, isError } = useQuery("homePageFeature", getFeatureArticles);

    return (
        <div className="col-12 clearfix">
        <div className="col-12 clearfix bg-white p-3 border float-start">
            { isLoading && <LocalLoadingSpinner /> }
            { data && (
                <>
                    <div className="col-12 col-md-6 float-start">
                        <HomeFeatureArticleCard data={data[0]} titleType="h2" coverphotoType="med_photos" />
                    </div>
                    <div className="col-12 col-md-6 float-end ps-md-3">
                        <LongArticleCard coverphoto="med_photos" ellipsis={false} flip={true} data={data[1]} />
                        <LongArticleCard coverphoto="med_photos" ellipsis={false} flip={true} data={data[2]} />
                        <LongArticleCard coverphoto="med_photos" ellipsis={false} flip={true} data={data[3]} />
                    </div>
                </>
            )}
        </div>
        </div>
    )
}