import {
    FaEllipsisH as More,
    FaRegBookmark as Bookmark,
    FaRegHeart as Love,
    FaRegShareSquare as Share
} from "react-icons/fa";
import React from "react";

export default class ArticleButtonsBar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ul id="article-side-buttons-bar" className="list-unstyled position-fixed">
                <li className="rounded-3 my-3 p-2" role="button" tabIndex="0"><Love className="fs-2"/></li>
                <li className="rounded-3 my-3 p-2" role="button" tabIndex="0"><Share className="fs-2"/></li>
                <li className="rounded-3 my-3 p-2" role="button" tabIndex="0"><Bookmark className="fs-2"/></li>
                <li className="rounded-3 my-3 p-2 btn-group dropend" role="button" tabIndex="0">
                    <More className="fs-2 dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"/>
                        <ul className="dropdown-menu mx-3">
                            <li>Facebook</li>
                            <li>Twitter</li>
                        </ul>
                </li>
            </ul>
        )
    }
}