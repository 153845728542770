import React, {useEffect} from "react";
import {createSearchParams, Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import mainLogo from "../assets/img/logo.png"
import axios from "axios";
import {useQuery} from "react-query";
import Container from "react-bootstrap/Container";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';

export default function Header () {

    function fetchHeader() {
        return axios.get("https://www.litemoods.com/nodeapp/node/categories/main").then(res => res.data);
    }

    let [searchParams, setSearchParams] = useSearchParams()
    let q = searchParams.get("q")
    if( q === null ) { q = "" }
    const navigate = useNavigate();
    const location = useLocation();
    function handleSubmit(event) {
        event.preventDefault();
        navigate({
            pathname: "/search",
            search: createSearchParams({
                "q": event.target.q.value
            }).toString()
        });
    }

    useEffect(() => {
        if(location.pathname !== "/search") {
            document.getElementById("headerSearchInput").value = ""
        } else {
            document.getElementById("headerSearchInput").value = q
        }
    } , [location]);

    const { data, isLoading, isFetching, isError } = useQuery("websiteSections", fetchHeader);
    return (
        <Navbar id="theHeader" className="rounded-0 shadow-sm" fixed="top" bg="light" expand="lg" style={{ zIndex: "1" }}>
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img src={mainLogo} alt="" height="30"
                             className="d-inline-block align-text-top" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/" className="nav-link text-dark fw-bold">رئيسية</Link>
                        { isLoading && (
                            <li className="d-flex align-items-center">
                                <div className="spinner-grow spinner-grow-sm" role="status"></div>
                                <div className="spinner-grow spinner-grow-sm" role="status"></div>
                                <div className="spinner-grow spinner-grow-sm" role="status"></div>
                            </li>

                        ) }
                        { data && data.map((item, i) => (
                                <Link to={ "/cat/" + item.id + "/" + item.title + "/1" } key={i} className="nav-link text-dark fw-bold">{ item.title }</Link>
                            )
                        )}
                        <Link to="/about" className="nav-link text-dark fw-bold">عن الموقع</Link>

                        <Link to="/contact" className="nav-link text-dark fw-bold">اتصل بنا</Link>
                    </Nav>
                    <Form className="d-flex" action="/search" onSubmit={ handleSubmit }>
                        <Form.Control
                            id="headerSearchInput"
                            type="search"
                            placeholder="بحث"
                            className=""
                            aria-label="Search"
                            name="q"
                        />
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

}