import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { useQuery } from "react-query";
import axios from "axios";
import Sidebar from "./Sidebar";
import ArticleButtonsBar from "./ArticleButtonsBar";
import ArticleBody from "./ArticleBody";
import TagsBar from "./TagsBar";


export default function Article(props)
{
    const { id } = useParams();

    function fetchArticle() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/" + id).then(res => res.data);
    }
    const { data, isLoading, isFetching, isError } = useQuery("article" + id, fetchArticle);
    useEffect( () => {
        axios.get("https://www.litemoods.com/nodeapp/node/articles/view_count/" + id).then(res => res.data)
    }, [])

    return (
        <>
        <div className="container pb-5 pt-2 h-100 clearfix" style={{ minHeight: window.outerHeight + "px" }}>
            { isLoading && <LoadingSpinner /> }
            { data && (
                <div className="col-12">
                    <div className="d-none d-lg-block col-lg-4 float-end ps-3">
                        <Sidebar count="10" />
                        <TagsBar count="20" />
                    </div>
                    <div className="col-12 col-lg-8 float-start">
                        <div className="d-none d-lg-block col-lg-1 float-start position-relative">
                            <ArticleButtonsBar />
                        </div>
                        <div className="col-12 col-lg-11 float-end">
                            <ArticleBody data={ data }/>
                        </div>

                    </div>
                </div>
            )}
        </div>
            <div className="clearfix"></div>
        </>
    )
}