import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";


import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Researches from "./components/Category";
import Article from "./components/Article";
import Contact from "./components/Contact";
import Category from "./components/Category";
import Footer from "./components/Footer";
import Tag from "./components/Tag";
import Search from "./components/Search";
import ScrollToTop from "./components/ScrollToTop";


function App() {
  return (
        <BrowserRouter>
            <ScrollToTop />
            <div className="container-fluid px-0" style={{ paddingTop: "56px", direction: "rtl", textAlign: "right" }}>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/researches" element={<Researches />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/article/:id/:title" element={<Article />} />
                    <Route path="/cat/:id/:title" element={<Category />} />
                    <Route path="/cat/:id/:title/:page" element={<Category />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/tag/:tag" element={<Tag />} />
                    <Route path="/tag/:tag/:page" element={<Tag />} />
                </Routes>
                <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
            <Footer />
        </BrowserRouter>
  );
}

export default App;