import {Link} from "react-router-dom";
import React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import DOMPurify from "dompurify";

export default function Tags(props) {
    const sanitizer = DOMPurify.sanitize;
    function fetchArticle() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/tags/" + props.articleId).then(res => res.data);
    }
    const { data, isLoading, isFetching, isError } = useQuery("articleTags" + props.articleId, fetchArticle);
    return (
        <>
        { isLoading && <LocalLoadingSpinner /> }
        { data && data.map((item, index) => {
            return (<Link className="tag_element btn btn-sm btn-outline-dark border-white border-1 text-black-50" key={index} to={"/tag/" + sanitizer( item.tag_name )}>#{sanitizer( item.tag_name )}</Link>)
        })}
        </>
    )
}