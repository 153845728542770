import React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import FixedPageHeader from "./FixedPageHeader";
import {Helmet} from "react-helmet";

export default function Contact() {
    function getWebsiteInfo() {
            return axios.get("https://www.litemoods.com/nodeapp/node/info").then(res => res.data);
    }

    const { data, isLoading, isError, isFetching } = useQuery("websiteInfo", getWebsiteInfo);
    function createMarkup( html ) {
        return { __html: html };
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>اتصل بنا</title>
            </Helmet>

            <form>
                <div className="col-12 h-100 clearfix" style={{ minHeight: window.outerHeight + "px" }}>
                    <div className="col-12 text-center">
                        <FixedPageHeader htmlTitle="اتصل بنا" />
                    </div>
                    <div className="container pt-4">
                        <div className="card">
                            <div className="card-body">
                                { data && (
                                    <div className="col-12 mt-2 pb-4">
                                        <div dangerouslySetInnerHTML={createMarkup(data.contact_en)} />
                                    </div>
                                )}
                                <form>
                                    <div className="form-outline mb-4">
                                        <input type="text" id="form6Example3" className="form-control"/>
                                        <label className="form-label" htmlFor="form6Example3">الاسم الكامل</label>
                                    </div>


                                    <div className="form-outline mb-4">
                                        <input type="email" id="form6Example5" className="form-control"/>
                                        <label className="form-label" htmlFor="form6Example5">اليريد الالكتروني</label>
                                    </div>

                                    <div className="form-outline mb-4">
                                        <input type="text" id="form6Example6" className="form-control"/>
                                        <label className="form-label" htmlFor="form6Example6">الهاتف</label>
                                    </div>

                                    <div className="form-outline mb-4">
                                        <textarea className="form-control" id="form6Example7" rows="4"></textarea>
                                        <label className="form-label" htmlFor="form6Example7">نص الرسالة</label>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-block mb-4">إرسال</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </>
    )
}