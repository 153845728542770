import React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import LocalLoadingSpinner from "./LocalLoadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";

function TagsBar (props){

    const sanitizer = DOMPurify.sanitize;
    function fetchTags() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/most/tags/" + props.count).then(res => res.data);
    }
    const { data, isLoading, isFetching, isError } = useQuery("mostUsedTags", fetchTags);

    return (
        <div id="tagsBar" className="p-3 bg-white border rounded-3 overflow-hidden mb-3">
            <h2 className="pb-2"><span>أوسمة</span> <FontAwesomeIcon className="fs-4 text-secondary" icon={ faArrowTrendUp } /></h2>
            <div>
                { isLoading && <LocalLoadingSpinner /> }
                { data && !isLoading && data.map( (item, i) => {
                    return (
                        <Link className="tag_element btn btn-sm btn-outline-dark border-white border-1 text-black-50" key={i} to={"/tag/" + sanitizer( item.tag_name )}>#{sanitizer( item.tag_name )}</Link>
                    )
                }) }
            </div>
        </div>
    )
}

export default TagsBar;