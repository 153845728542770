import React from "react"
import axios from "axios";
import {useQuery} from "react-query";
import LoadingSpinner from "./LoadingSpinner";
import HomePageFeature from "./HomepageFeature";
import HomepageSection from "./HomepageSection";
import Sidebar from "./Sidebar";
import {Helmet} from "react-helmet";

export default function Home(){

    function fetchSections() {
        return axios.get("https://www.litemoods.com/nodeapp/node/categories/main").then(res => res.data);
    }

    const { data, isLoading, isFetching, isError } = useQuery("websiteSections", fetchSections);


    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>لايت مودز | الرئيسية</title>
            </Helmet>
            <div className="container pb-5 pt-2">
                { isLoading && <LoadingSpinner /> }
                <div className="">
                    <div className="col-12">
                    </div>
                    <div className="container px-0 clearfix">
                        <div className="col-12">
                            <HomePageFeature />
                        </div>
                        <div className="col-12 mt-5">
                            <div className="col-12 col-md-8 float-start clearfix">
                                { data && !isLoading && data.map( (item, i) => {
                                    return (
                                        <HomepageSection data={item} key={item.id}/>
                                    )
                                })}
                            </div>
                            <div className="d-none d-md-block col-md-4 float-start">
                                <Sidebar count="10" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}