import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import './Style.css';

const queryClient = new QueryClient();
const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools />
    </QueryClientProvider>
);

serviceWorkerRegistration.register();