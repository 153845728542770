import React from "react"
import { useQuery } from "react-query";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function PageHeader(props){
    function getData() {
        return axios.get("https://www.litemoods.com/nodeapp/node/categories/" + props.catId).then(res => res.data);
    }
    const { data, isLoading, isError, isFetching } = useQuery("categoryInfo_" + props.catId, getData);

    return(
        <>
            { data && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ data.title }</title>
                </Helmet>
            ) }

            <div className="col-12 bg-dark text-white p-3">
                { data && (
                    <div className="col-12 clearfix">
                        <h1 className="fw-semi-bold mb-0">
                            { data.title }
                        </h1>
                        <div className="col-12 pt-2">
                        <span className="text-muted text-center">
                            <span><Link className="text-decoration-none text-muted" to="/"><FontAwesomeIcon icon={faHome} /> الرئيسية</Link></span>
                            <span className="px-1">/</span>
                            <span>{ data.title }</span>
                        </span>
                        </div>
                    </div>
                ) }
            </div>
        </>
    )
}