import React, {useEffect, useState} from "react"
import ArticleCard from "./ArticleCard";
import { useQuery } from "react-query";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import {useParams} from "react-router-dom";
import PageHeader from "./PageHeader";
import Pagination from "./Pagination";
import LongArticleCard from "./LongArticleCard";
import Sidebar from "./Sidebar";

export default function Category(){

    let { id, page, title } = useParams()
    if ( page === undefined ) { page = 1 }
    function getArticles() {
        return axios.get("https://www.litemoods.com/nodeapp/node/articles/cat/" + id + "/" + page).then(res => res.data);
    }
    const { data, isLoading, isError, isFetching } = useQuery("category_" + id + "_" + page, getArticles);

    return(
        <>
        <div className="col-12 clearfix">
            { isLoading && <LoadingSpinner /> }
            <div className="">
                <div className="col-12 text-center">
                    <PageHeader catId={ id } />
                </div>
                <div className="container clearfix">
                <div className="container mt-1 h-100 col-12 col-md-8 float-start ps-md-0" style={{ minHeight: window.innerHeight + "px" }}>
                    { data && !isLoading && data.map( (item, i) => {
                        return (
                            <LongArticleCard data={item} key={item.article_id}/>
                        )
                    }) }
                    { data && data.length === 0 && (
                        <div className="col-12 text-center w-100 pt-5">
                            <h4 className="text-black-50 fw-bold">لا يوجد محتوى حالياً</h4>
                        </div>
                    )}
                    <div className="clearfix"></div>
                </div>
                <div className="d-none d-md-block col-md-4 float-start">
                    <Sidebar count="15" />
                </div>
                </div>
            </div>
        </div>

        <div className="col-12 py-2">
            <Pagination page={ page } catId={ id } catTitle={title} />
        </div>
        </>
    )
}