import React, { Fragment } from "react";
import ImagePlaceholder from "./ImagePlaceholder";

export class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    handleImageLoaded() {
        this.setState({ loaded: true });
    }

    render() {
        const { loaded } = this.state;
        const imageStyle = !loaded ? { display: "none" } : {};
        return (
            <div className = "imageHolder" >
                {!loaded && <ImagePlaceholder/> }
                <img src={"https://www.litemoods.com/" + this.props.data.coverphoto_type + "/blog/" + this.props.data.coverphoto} className="ratio-16x9 img-fluid" alt="Cover Photo" style={imageStyle} onLoad={this.handleImageLoaded.bind(this)} />
            </div>
        )
    }
}