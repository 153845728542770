import React from "react";
import {Link} from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import DOMPurify from "dompurify";
import {Image} from "./Image";

export default function LongArticleCard(props)
{
    // Create an HTML sanitizer to filter XSS
    const sanitizer = DOMPurify.sanitize;
    let cardClass = "card shadow-sm", coverphoto
    let imgFloatClass = 'float-start', textFloatClass = 'float-end ps-md-3'

    if (props.flip) {
        imgFloatClass = 'float-end'
        textFloatClass = 'float-start pe-md-3'
    }

    if( props.shadow && props.shadow === "false") {
        cardClass = "card"
    }
    if( props.border && props.border === "false" ) {
        cardClass += " border-0"
    }
    switch (props.coverphoto) {
        case "med_photos":
            coverphoto = "med_photos"
            break;
        default:
            coverphoto = "thumbs"
    }

    return (
        <div className="col-12 text-start py-3">
            <div className="clearfix border shadow-0 border-0 bg-white">
                <div className={imgFloatClass + " col-12 col-md-4"}>
                    <Image data={{'coverphoto_type':  coverphoto, 'coverphoto': sanitizer(props.data.coverphoto)}}/>
                </div>
                <div className={textFloatClass + " col-12 col-md-8 ps-0"}>
                    { props.data.cat_title && (
                        <div className="text-primary fw-bolder border-info border-4 border-start mt-2 mt-md-0">
                            <Link className="text-decoration-none ps-2" to={"/cat/" + props.data.cat_id + "/" + props.data.cat_title}>{ sanitizer(props.data.cat_title, {}) }</Link>
                        </div>
                    ) }
                    { props.title === 'h6' ? (
                        <h6 className="card-title py-2">
                            <Link className="text-dark text-decoration-none" to={"/article/" + props.data.article_id + "/" + props.data.title.replaceAll(' ', '-')}>
                                { props.data.ellipsis === false ? (
                                        DOMPurify.sanitize(props.data.title, {})
                                ) : (
                                    <Dotdotdot clamp={2}>
                                        {DOMPurify.sanitize(props.data.title, {})}
                                    </Dotdotdot>
                                ) }
                            </Link>
                        </h6>
                    ) : (
                        <h4 className="card-title py-2">
                            <Link className="text-dark text-decoration-none" to={"/article/" + props.data.article_id + "/" + props.data.title.replaceAll(' ', '-')}>
                                { props.data.ellipsis === false ? (
                                    DOMPurify.sanitize(props.data.title, {})
                                ) : (
                                    <Dotdotdot clamp={2}>
                                        {DOMPurify.sanitize(props.data.title, {})}
                                    </Dotdotdot>
                                ) }
                            </Link>
                        </h4>
                    ) }

                    { props.teaser !== "false" && (
                        <span>
                            { props.data.ellipsis === false ? (
                                <p className="card-text text-black-50 fs-6 py-2">{DOMPurify.sanitize(props.data.teaser, {})}</p>
                            ) : (
                                <Dotdotdot clamp={3}>
                                    <p className="card-text text-black-50 fs-6 py-2">{DOMPurify.sanitize(props.data.teaser, {})}</p>
                                </Dotdotdot>
                            ) }
                        </span>
                    ) }
                </div>
            </div>
        </div>
    )
}