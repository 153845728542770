import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import {useQuery} from "react-query";
import FixedPageHeader from "./FixedPageHeader";
import {Helmet} from "react-helmet";

export default function About() {
    function getWebsiteInfo() {
        return axios.get("https://www.litemoods.com/nodeapp/node/info").then(res => res.data);
    }

    const { data, isLoading, isError, isFetching } = useQuery("websiteInfo", getWebsiteInfo);

    function createMarkup( html ) {
        return { __html: html };
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>عن الموقع</title>
            </Helmet>
            <div className="col-12 h-100 clearfix" style={{minHeight: window.outerHeight + "px" }}>
                { isLoading && <LoadingSpinner /> }

                <div className="col-12">
                    <div className="col-12 text-center">
                        <FixedPageHeader htmlTitle="عن الموقع" />
                    </div>
                    { data && (
                        <div className="container">
                            <div className="col-12">
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <div style={{ fontSize: '20px' }} dangerouslySetInnerHTML={createMarkup(data.about_en)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </>
    )
}